import React from "react";
import SidebarComponent from "./SidebarComponent";
import HeaderComponent from "./HeaderComponent";
const AuthorizedLayoutComponent = ({ children }) => {
  return (
    <>
      <div className="main-wrapper">
        <SidebarComponent />
        <HeaderComponent />
        <div name="mainContent" className="main-content">          
          <section className="section">{children}</section>
        </div>
      </div>
    </>
  );
};

export default AuthorizedLayoutComponent;
