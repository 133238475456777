import moment from "moment/min/moment-with-locales";

export const DefaultFormat = {
  DateTime24Format: "dd/MM/yyyy HH:mm",
  DateTimeFullFormat: "dd/MM/yyyy hh:mm:ss",
  DateFormat: "dd/MM/yyyy",
  TimeFormat: "HH:mm",

  MomentDisplayDateTime24Format: "DD/MM/yyyy HH:mm",
  MomentDisplayDateTimeFullFormat: "DD/MM/yyyy hh:mm:ss",
  MomentDisplayDateFormat: "DD/MM/yyyy",
  MomentDisplayTimeFormat: "HH:mm",

  SqlDateTime24Format: "YYYY-MM-DD HH:mm",
  SqlDateTimeFullFormat: "YYYY-MM-DD hh:mm:ss",
  SqlDateFormat: "YYYY-MM-DD",
  SqlTimeFormat: "HH:mm",
  SqlStartDateFormat: "YYYY-MM-DD 00:00:00",
  SqlEndDateFormat: "YYYY-MM-DD 23:59:59",
};
export const DateFilter = {
  CurrentDateTime: new Date(),
  StartDateTime: new Date(moment(new Date()).add(-1, "days")),
  EndDateTime: new Date(),
};
export const SymbolFormats = {
  ruppesFormat: "fas fa-rupee-sign",
  dollarFormat: "fas fa-dollar-sign",
};
export const PrecisionFormats = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
};
export const UserType = {
  Administrator: 5,
  HotelDepartment: 11,
};
export const RegEx = {
  Numeric: /^[0-9\b]+$/,
  AlphaNumeric: /^[A-Za-z0-9-]*$/,
  Decimal2Digit: /^\d*(\.\d{0,2})?$/,
  DecimalRulePattern2Digit: /^\\d{0,5}(\\.{0,1})\\d{0,2}$/,
  Mobile: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  Email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
export const FolderName = {
  HotelBookingRate: "HotelBookingRate",
  Hotel: "Hotel",
  HotelMeal: "HotelMeal",
};
// const renumbersymbolexp = /[^0-9]/g;
//                 const lowercaseexp = /[^a-z]/g;
//                 const uppercaseexp = /[^A-Z]/g;
//                 const specialcharacters = /[^!@#$%^&*]/g;
