import React from "react";
import { SessionStorage } from "../helper/UserSession";

export default function UserProfileComponent() {
  const handleOnLogout = async (e) => {
    e.preventDefault();
    try {
      sessionStorage.clear();
      window.location.href = "/";
    } catch (error) {
      console.log("UerProfileComponent/HandleOnLogout");
    }
  };
  return (
    <>
      <ul className="navbar-nav navbar-right">
        <li>
          <span className="pr-3 text-white">{`${SessionStorage.FirstName} ${SessionStorage.LastName}`}</span>
        </li>
        <li className="dropdown mr-0 user-profile">
          <a
            href="#"
            data-toggle="dropdown"
            className="dropdown-toggle nav-link-user"
          >
            <img
              alt="image"
              src="./assets/img/avatar/avatar-1.png"
              className="rounded-circle mr-1"
              style={{ width: "20px" }}
            />
          </a>
          <div className="dropdown-menu dropdown-list dropdown-menu-right rounded-vlg">
            <div className="dropdown-header pb-0">
              Hey {SessionStorage.UserName}!
            </div>
            <div className="text-center mt-3 mb-3">
              <figure className="avatar mr-2 avatar-xl">
                <img src="../assets/img/avatar/avatar-1.png" alt="..." />
                <i className="avatar-presence online"></i>
              </figure>
            </div>
            <a
              href="#"
              className="dropdown-item has-icon"
              // onClick={(e) => {
              //   this.openchangesPasswordModel(e);
              // }}
            >
              <i className="fas fa-lock-open"></i> Change Password
            </a>
            <a
              href="#"
              onClick={handleOnLogout}
              className="dropdown-item has-icon mb-2"
            >
              <i className="fas fa-sign-out-alt"></i> Logout
            </a>
          </div>
        </li>
      </ul>
    </>
  );
}
