import { UserType } from "./fixcode";
export const SessionStorage = {
  IsAuthorized:
    JSON.parse(sessionStorage.getItem("session_authorized")) === true,
  IsAdminUserLoggedIn:
    JSON.parse(sessionStorage.getItem("session_usertypeid")) ===
    UserType.Administrator,
  UserId: Number(sessionStorage.getItem("session_userid")),
  UserName: sessionStorage.getItem("session_username"),
  FirstName: sessionStorage.getItem("session_firstname"),
  LastName: sessionStorage.getItem("session_lastname"),
  UserTypeId: Number(sessionStorage.getItem("session_usertypeid")),
  UserTypeName: sessionStorage.getItem("session_usertype"),
  AirlineCode: sessionStorage.getItem("session_airlinecode"),
  PhoneNumber: sessionStorage.getItem("session_userphonenumber"),
  Email: sessionStorage.getItem("session_useremail"),
  HotelAccessCount: parseInt(
    sessionStorage.getItem("session_hotelAccessCount")
  ),
  AirlineAccessCount: parseInt(
    sessionStorage.getItem("session_airlineAccessCount")
  ),
  HotelCode: sessionStorage.getItem("session_hotelCode"),
  HotelName: sessionStorage.getItem("session_hotelName"),
  AirlineName: sessionStorage.getItem("session_airlineName"),
};
function ToString(value) {
  try {
    debugger;
    return value ?? "";
  } catch (error) {
    console.log(error);
  }
  return "";
}
