import { lazy } from "react";
export function LazyLoad(path) {
  return lazy(() => {
    const promise = import(`${path}`);
    return promise.then((module) => {
      if (module.default) {
        return module;
      } else {
        return {
          default: module[path.split("/").pop()],
        };
      }
    });
  });
}
