import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import React, { Suspense } from "react";
import {
  DataGridSkeletonPageSimple,
  DataGridSkeletonPageActionOne,
  DataGridSkeletonPageActionTwo,
  DataGridSkeletonPageFilter,
  DataGridSkeletonPageActionFilter,
  DataGridSkeletonPageHeaderFilter,
  DataGridSkeletonPageHeaderFilterTwo,
  DataGridSkeletonPageActionThree,
} from "./skeleton/GridSkeleton";
import { LazyLoad } from "./LazyLoad";
import AuthorizedLayoutComponent from "./shared/AuthorizedLayoutComponent";
import { SessionStorage } from "./helper/UserSession";
const LoginPage = LazyLoad("./components/LoginPage");
const DahsboardPage = LazyLoad("./components/dashboard/DahsboardPage");
const User = LazyLoad("./components/admin/User");
const RoomType = LazyLoad("./components/admin/RoomType");
const RoomNumber = LazyLoad("./components/admin/RoomNumber");
const Hotel = LazyLoad("./components/hotel/Hotel");
const Confirmed = LazyLoad("./components/booking/Confirmed");
const NonConfirmed = LazyLoad("./components/booking/NonConfirmed");
const Rejected = LazyLoad("./components/booking/Rejected");
const CrewCancelRequest = LazyLoad("./components/booking/CrewCancelRequest");
const InvoiceBillable = LazyLoad("./components/invoice/Billable");
const GenerateInvoice = LazyLoad("./components/invoice/GenerateInvoice");
const InvoiceListing = LazyLoad("./components/invoice/InvoiceListing");

const ReportConfirmed = LazyLoad("./components/report/ReportConfirmed");
const ReportNonConfirmed = LazyLoad("./components/report/ReportNonConfirmed");
const ContractedRate = LazyLoad("./components/rate/ContractedRate");
const PageNotFound = LazyLoad("./components/PageNotFound");
const PageUnderDevelopment = LazyLoad("./components/PageUnderDevelopment");

function App() {
  const isAuthorized = SessionStorage.IsAuthorized === true;
  return (
    <>
      {isAuthorized === true ? (
        <AuthorizedLayoutComponent>
          <Routes>
            <Route path="/" element={<AuthorizedRouteWrapper />}>
              <Route path="/" element={<DahsboardPage />} />
              <Route path="*" element={<PageUnderDevelopment />} />
              <Route path="/dashboard" element={<DahsboardPage />} />
              <Route path="/user" element={<User />} />
              <Route path="/roomtype" element={<RoomType />} />
              <Route path="/roomnumber" element={<RoomNumber />} />
              <Route path="/hotel" element={<Hotel />} />
              <Route path="/booking/nonconfirmed" element={<NonConfirmed />} />
              <Route path="/booking/confirmed" element={<Confirmed />} />
              <Route path="/booking/rejected" element={<Rejected />} />
              <Route path="/booking/cancel" element={<CrewCancelRequest />} />
              <Route path="/invoice/billable" element={<InvoiceBillable />} />
              <Route
                path="/invoice/generateinvoice"
                element={<GenerateInvoice />}
              />
              <Route
                path="/invoice/invoicelisting"
                element={<InvoiceListing />}
              />
              <Route path="/rate/contract" element={<ContractedRate />} />
              <Route path="/report/confirmed" element={<ReportConfirmed />} />
              <Route
                path="/report/nonconfirmed"
                element={<ReportNonConfirmed />}
              />
            </Route>
          </Routes>
        </AuthorizedLayoutComponent>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="*" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      )}
    </>
  );
}

function AuthorizedRouteWrapper() {
  return (
    <>
      <Suspense fallback={<SkeletonLoaderComponent />}>
        <Outlet />
      </Suspense>
    </>
  );
}
function SkeletonLoaderComponent() {
  const { pathname } = useLocation();
  switch (pathname) {
    case "/hotel":
      return <DataGridSkeletonPageSimple />;
    case "/user":
      return <DataGridSkeletonPageActionOne />;
    case "/roomtype":
    case "/roomnumber":
    case "/rate/contract":
      return SessionStorage.HotelAccessCount > 1 ? (
        <DataGridSkeletonPageActionFilter />
      ) : (
        <DataGridSkeletonPageActionOne />
      );
    case "/booking/nonconfirmed":
    case "/booking/confirmed":
    case "/booking/cancel":
    case "/invoice/billable":
    case "/booking/rejected":
      return SessionStorage.HotelAccessCount > 1 ? (
        <DataGridSkeletonPageActionThree />
      ) : (
        <DataGridSkeletonPageActionTwo />
      );
    case "/invoice/generateinvoice":
      return <DataGridSkeletonPageHeaderFilterTwo />;
    default:
      return (
        <>
          <div className="text-center">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      );
  }
}
export default App;
