import React, { useEffect, useLayoutEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export function DataGridSkeletonPageSimple() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-3 mb-2 p-0"></div>
          <div className="col-sm-7 col-md-7 col-3"></div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function DataGridSkeletonPageActionOne() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-1 col-md-1 col-1 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-4 col-md-4 col-5 mb-2"></div>
          <div className="col-sm-4 col-md-4 col-4 mb-2 p-0"></div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function DataGridSkeletonPageActionTwo() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-2 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-2 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-3 col-md-3 col-3 mb-2"></div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-2 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function DataGridSkeletonPageActionThree() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-2 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-2 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-2 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>

          <div className="col-sm-1 col-md-1 col-1 mb-2"></div>

          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-2 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function DataGridSkeletonPageFilter() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-3 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-7 col-md-7 col-3"></div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function DataGridSkeletonPageActionFilter() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-1 col-md-1 col-1 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-4 col-md-4 col-4 mb-2"></div>

          <div className="col-sm-3 col-md-3 col-3 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function DataGridSkeletonPageHeaderFilter() {
  return (
    <>
      <div className="card shadow-sm  p-3 mb-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-12 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-3 col-md-3 col-12 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-12 mb-2 ">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-12 mb-2 ">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-5 col-md-5 col-12"></div>
        </div>
      </div>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-3 mb-2 p-0"></div>
          <div className="col-sm-7 col-md-7 col-3"></div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function DataGridSkeletonPageHeaderFilterTwo() {
  return (
    <>
      <div className="card shadow-sm  p-3 mb-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-12 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-3 col-md-3 col-12 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-12 mb-2 ">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-12 mb-2 ">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-5 col-md-5 col-12"></div>
        </div>
      </div>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-5 col-md-5 col-5 mb-2 p-0"></div>
          <div className="col-sm-2 col-md-2 col-2 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-2 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={5} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function GridRow2() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-3 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-7 col-md-7 col-3"></div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={20} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
export function GridRow3() {
  return (
    <>
      <div className="card shadow-sm rounded-vlg p-3">
        <div className="row">
          <div className="col-sm-4 col-md-4 col-3 mb-2 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-5 col-md-5 col-3"></div>
          <div className="col-sm-1 col-md-1 col-1 mb-2">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-2 col-md-2 col-8 p-0 mb-2">
            <Skeleton height={30} />
          </div>
        </div>
        <div className="row  mb-2">
          <div className="col-sm-12 col-md-12 col-12 p-0">
            <Skeleton height={20} />
            <Skeleton height={2} />
            <Skeleton count={20} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
          <div className="col-sm-6 col-md-10 col-6"></div>
          <div className="col-sm-3 col-md-1 col-3 p-0">
            <Skeleton height={30} />
          </div>
        </div>
      </div>
    </>
  );
}
