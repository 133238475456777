import React, { useEffect, useId, useState } from "react";
import { SessionStorage } from "../helper/UserSession";
export default function SidebarComponent() {
  const controller = new AbortController();
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);
  return (
    <>
      <div
        className="main-sidebar"
        style={{ overflow: "hidden", outline: "none" }}
      >
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <a href="/">
              <img
                alt="Hotel"
                src="../assets/img/logo-hotel.png"
                width="180px"
              />
            </a>
            {!(SessionStorage.HotelAccessCount > 1) &&
            SessionStorage.HotelName.length > 30
              ? `${SessionStorage.HotelName.slice(0, 30)}..`
              : SessionStorage.HotelName}
          </div>

          <div className="sidebar-brand-silverstar-sm">
            <a href="/">H</a>
          </div>
          <div className="companyLogoBox sidebar-brand-sm">
            <img src="../assets/img/defaultlogo.png" />
          </div>
          <div className="sidebar-brand sidebar-brand-sm">
            {/* <a href="/">{sessionStorage.getItem("session_accountname").charAt(0).toLowerCase().trim()}</a> */}
          </div>
          <ul className="sidebar-menu">
            <li className="nav-item">
              <a title="Dashboard" className="nav-link" href="/">
                <i className="fas fa-chalkboard"></i>
                <span>Dashboard</span>
              </a>
            </li>
            {SessionStorage.IsAdminUserLoggedIn && (
              <li className="nav-item">
                <a
                  href="/"
                  className="nav-link has-dropdown"
                  title="Booking"
                  data-toggle="dropdown"
                >
                  <i className="fas fa-calendar-alt"></i>
                  <span>Admin</span>
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a className="nav-link" title="Non Comfirmed" href="/user">
                      <i className="fas fa-bed"></i>
                      <span>User</span>
                    </a>
                  </li>
                </ul>
              </li>
            )}
            <li className="nav-item">
              <a
                href="/"
                className="nav-link has-dropdown"
                title="Booking"
                data-toggle="dropdown"
              >
                <i className="fas fa-calendar-alt"></i>
                <span>Master</span>
              </a>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Non Comfirmed"
                    href="/roomtype"
                  >
                    <i className="fas fa-bed"></i>
                    <span>Room Type</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Non Comfirmed"
                    href="/roomnumber"
                  >
                    <i className="fas fa-bed"></i>
                    <span>Room Number</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a title="Hotel" className="nav-link" href="/hotel">
                <i className="fas fa-hotel"></i>
                <span>Hotel</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className="nav-link has-dropdown"
                title="Booking"
                data-toggle="dropdown"
              >
                <i className="fas fa-calendar-alt"></i>
                <span>Booking</span>
              </a>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Non Confirmed"
                    href="/booking/nonconfirmed"
                  >
                    <i className="fas fa-bed"></i>
                    <span>Non Confirmed</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Confirmed"
                    data-toggle="nav-link"
                    href="/booking/confirmed"
                  >
                    <i className="fas fa-bed"></i>
                    <span>Confirmed</span>
                  </a>
                </li>
                {SessionStorage.IsAdminUserLoggedIn && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      title="Confirmed"
                      data-toggle="nav-link"
                      href="/booking/rejected"
                    >
                      <i className="fas fa-bed"></i>
                      <span>Rejected</span>
                    </a>
                  </li>
                )}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Crew Cancel Request"
                    data-toggle="nav-link"
                    href="/booking/cancel"
                  >
                    <i className="fas fa-user-slash"></i>
                    <span>Crew Cancel Request</span>
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <a
                href="#"
                className="nav-link has-dropdown"
                title="Invoicing"
                data-toggle="dropdown"
              >
                <i className="far fa-file-alt"></i>
                <span>Invoicing</span>
              </a>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Hotel Billable Booking"
                    data-toggle="nav-link"
                    href="/invoice/billable"
                  >
                    <i className="fas fa-rupee-sign"></i>
                    <span>Hotel Billable Booking</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Generate Invoice"
                    data-toggle="nav-link"
                    href="/invoice/generateinvoice"
                  >
                    <i className="far fa-file-alt"></i>
                    <span>Generate Invoice</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Invoice Listing"
                    data-toggle="nav-link"
                    href="/invoice/invoicelisting"
                  >
                    <i className="fas fa-server"></i>
                    <span>Invoice Listing</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                title="Contracted Rates"
                className="nav-link"
                href="/rate/contract"
              >
                <i className="fas fa-rupee-sign"></i>
                <span>Contracted Rates</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className="nav-link has-dropdown"
                title="Booking"
                data-toggle="dropdown"
              >
                <i className="fas fa-calendar-alt"></i>
                <span>Report</span>
              </a>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Non Comfirmed"
                    href="/report/confirmed"
                  >
                    <i className="fas fa-bed"></i>
                    <span>Confirmed</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    title="Non Comfirmed"
                    href="/report/nonconfirmed"
                  >
                    <i className="fas fa-bed"></i>
                    <span>Non Confirmed</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </aside>
      </div>
      <div className="plexitechPoweredByLogo">
        powered by
        <img src="../assets/img/logo-plexitech.png" />
      </div>
    </>
  );
}
