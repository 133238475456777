import React from "react";
import UserProfileComponent from "./UserProfileComponent";

export default function HeaderComponent() {
  return (
    <>
      <div className="navbar-bg"></div>
      <nav
        className="navbar navbar-custom navbar-expand-lg main-navbar"
        name="topGeneralBar"
      >
        <form className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a
                href="#"
                data-toggle="sidebar"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>
            <li className="nav-item">
              <h6 className="mb-0">
                <label id="lblPageTitle"></label>
              </h6>
            </li>
          </ul>
        </form>
        <UserProfileComponent />
      </nav>
    </>
  );
}
